<template>
  <div v-if="data?.statistieken">
    <UITableResponsive :items="data.statistieken || []" class="text-center w-auto" :headers="headers">
      <template #item-Datum="{ item }">
        <div class="flex flex-row gap-1">
          <span v-if="item.day">{{ item.day }}</span>
          <span v-if="item.month">{{ item.day ? monthToString(item.month) : capitalize(monthToString(item.month)) }}</span>
          <span v-if="item.year">{{ item.year }}</span>
        </div>
      </template>
      <template #item-Enkele_ritten="{ item }">
        <div>
          <i class="fas fa-car text-gray-400"></i>
          {{ serieValues.Enkele_ritten(item) }}
        </div>
      </template>
      <template #item-Onderaannemer="{ item }">
        <div v-if="item.user_id">
          {{ store.getters.chauffeur(item.user_id)?.label }}
        </div>
        <small v-else class="text-gray-500 italic">Niet van toepassing</small>
      </template>
      <template #item-Enkele_rit_totaalprijs="{ item }">
        <div>
          &euro;&nbsp;{{ formatPrice(serieValues.Enkele_rit_totaalprijs(item)) }}
        </div>
      </template>
      <template #item-Totaal_basisprijs="{ item }">
        <div>
          &euro;&nbsp;{{ formatPrice(serieValues.Totaal_basisprijs(item)) }}
        </div>
      </template>
      <template #item-Totaal_commisie="{ item }">
        <div>
          &euro;&nbsp;{{ formatPrice(serieValues.Totaal_commisie(item)) }}
        </div>
      </template>
      <template #item-Winstmarge="{ item }">
        <div>
          {{ formatPrice(serieValues.Winstmarge(item)) }}&nbsp;%
        </div>
      </template>
      <template #item-Kilometers="{ item }">
        <div>
          {{ formatPrice(serieValues.Kilometers(item)) }}&nbsp;%
        </div>
      </template>
    </UITableResponsive>

    <div v-if="data.statistieken.length > 1 && !groupByUser" :key="selectedCategory" class="mt-8">
      <UISelect v-model="selectedCategory" label="Grafiek" :options="categories" />
      <ChartsBar :label="selectedCategory" :categories="chartCategories" :values="chartValues" />
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, ref } from 'vue'
import { useStore } from 'vuex'
import clone from 'just-clone'

import UISelect from '@/components/UI/Select/Select.vue'
import UITableResponsive from '@/components/UI/Table/Responsive.vue'

import { monthToString } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'
import { capitalize, formatHeader } from '@/functions/formatText'
import useGetApi from '@/hooks/useGetApi'

const props = defineProps({
  is_bevestigd: {
    type: Boolean,
    default: true,
  },
  groupByUser: {
    type: Boolean,
    default: false,
  },
  timefield: {
    type: String,
    default: null,
  },
  filters: {
    type: Object,
    required: true,
  },
})

const store = useStore()

const ChartsBar = defineAsyncComponent(() => import('@/components/Charts/Bar.vue'))

const headers = computed(() => [
  'Datum',
  props.groupByUser ? 'Onderaannemer' : null,
  'Enkele_ritten', // aantal_enkele_ritten
  'Enkele_rit_totaalprijs', // enkeleRitPrijs
  'Totaal_basisprijs', // onderaannemerBasisPrijs
  'Totaal_commisie', // commisie
  'Winstmarge', // winstmarge
  'Kilometers', // kilometers
].filter(el => !!el))

const serieValues = {
  Datum: item => [item.day, item.month ? monthToString(item.month) : null, item.year].filter(el => !!el).join(' '),
  Enkele_ritten: item => item.aantal_enkele_ritten,
  Enkele_rit_totaalprijs: item => item.enkeleRitPrijs,
  Totaal_basisprijs: item => item.onderaannemerBasisPrijs,
  Totaal_commisie: item => item.onderaannemerCommissie,
  Winstmarge: item => item.winstmarge,
  Kilometers: item => item.kilometers,
}

const apiData = computed(() => {
  const result = clone(props.filters)
  result.groupByUser = !!props.groupByUser
  return result
})

const { data } = useGetApi('/api/dashboard/stats/onderaanneming/list', apiData, { watch: true })

const selectedCategory = ref('Enkele_ritten')

const categories = computed(() => {
  if (!data.value?.statistieken) return []
  return Object.keys(serieValues).filter(el => el !== 'Datum').map(el => ({ label: formatHeader(el), value: el }))
})
const chartCategories = computed(() => {
  if (!data.value?.statistieken) return []
  return data.value.statistieken.map(item => serieValues.Datum(item))
})
const chartValues = computed(() => {
  if (!data.value?.statistieken) return []
  // eslint-disable-next-line no-useless-call
  return data.value.statistieken.map(item => serieValues[selectedCategory.value].call(serieValues, item) || 0)
})
</script>
